import { styled } from '@linaria/react';

import { Button } from 'components/Controls';

import { COLOR, SIZE, TEXT } from 'theme/vars';

import { Service as _Service } from './components';

export const Wrapper = styled.section`
  @media (min-width: 1024px) {
    min-height: 100vh;
  }

  padding: 60px 0;

  display: flex;
  align-items: center;

  background-color: var(${COLOR.NEUTRAL_900});
`;

export const Content = styled.div`
  width: 100%;

  @media (min-width: 1280px) {
    padding: 0 110px;
    max-width: var(${SIZE.CONTENT_WIDTH});
    margin: auto;
  }

  @media (min-width: 420px) and (max-width: 1279px) {
    padding: 0 40px;
  }

  @media (max-width: 419px) {
    padding: 0 20px;
  }

  @media (min-width: 1024px) {
    display: flex;
    align-items: center;
  }

  @media (max-width: 1023px) {
    display: flex;
    flex-direction: column;
  }
`;

export const Text = styled.div`
  @media (min-width: 1024px) {
    margin-right: 20px;
  }
`;

export const Header = styled.h2`
  color: var(${COLOR.NEUTRAL_100});

  text-transform: uppercase;

  ${TEXT.SUBHEADING_LARGE}
`;

export const SubHeader = styled.h3`
  margin-top: 16px;
  white-space: pre-wrap;

  color: var(${COLOR.NEUTRAL_100});

  @media (min-width: 1280px) {
    ${TEXT.TITLE_2}
  }

  @media (min-width: 420px) and (max-width: 1279px) {
    ${TEXT.TITLE_3}
  }

  @media (max-width: 419px) {
    ${TEXT.TITLE_4}
  }
`;

export const Description = styled.p`
  position: relative;

  margin-top: 32px;

  max-width: 396px;

  color: var(${COLOR.NEUTRAL_100});

  ${TEXT.BODY_DEFAULT}

  @media (min-width: 1280px) {
    padding-left: 24px;
  }

  @media (max-width: 1279px) {
    padding-left: 16px;
  }

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 8px;
    bottom: 8px;
    left: 0;
    width: 2px;
    background-color: var(${COLOR.NEUTRAL_100});
  }
`;

export const ContactButton = styled(Button)`
  margin-top: 32px;
`;

export const Services = styled.div`
  display: flex;

  @media (min-width: 1024px) {
    align-items: center;
    margin-left: auto;
  }

  @media (max-width: 1023px) {
    flex-wrap: wrap;
  }
`;

export const Column = styled.div`
  @media (min-width: 1024px) {
    & + & {
      margin-left: 40px;
    }

    &:nth-child(2) {
      > div:nth-child(1) {
        transition-delay: 0.15s;
      }

      > div:nth-child(2) {
        transition-delay: 0.3s;
      }
    }
  }

  @media (max-width: 1023px) {
    display: flex;
    flex-wrap: wrap;

    &:first-child {
      order: 2;
    }

    &:nth-child(1) {
      > div {
        transition-delay: 0.3s;
      }
    }

    &:nth-child(2) {
      > div:nth-child(2) {
        transition-delay: 0.15s;
      }
    }
  }
`;

export const Service = styled(_Service)`
  @media (min-width: 1024px) {
    & + & {
      margin-top: 40px;
    }
  }

  @media (min-width: 380px) and (max-width: 1023px) {
    margin-top: 40px;
    margin-right: 40px;
  }

  @media (max-width: 379px) {
    margin-top: 40px;
  }
`;
