import * as React from 'react';

import { Helmet } from 'react-helmet';

import { Main, About, Works, Footer } from './components';

function Home() {
  return (
    <React.Fragment>
      <Helmet>
        <title>Anton Ignatev</title>

        <meta name='description' content='Software development expert' />
        <meta name='viewport' content='width=device-width, initial-scale=1' />
      </Helmet>

      <Main />
      <About />
      {/* <Works /> */}
      <Footer />
    </React.Fragment>
  );
}

export default Home;
