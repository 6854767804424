import { styled } from '@linaria/react';
import { SIZE, COLOR, TEXT, TRANSITION } from 'theme/vars';

import { Logo as LogoIcon } from 'theme/icons';

export const Wrapper = styled.footer`
  background-color: var(${COLOR.ORANGE_NORMAL});
  padding: 60px 0;
`;

export const Content = styled.div`
  @media (min-width: 1280px) {
    padding: 0 110px;
    max-width: var(${SIZE.CONTENT_WIDTH});
    margin: auto;
  }

  @media (min-width: 420px) and (max-width: 1279px) {
    padding: 0 40px;
  }

  @media (max-width: 419px) {
    padding: 0 20px;
  }
`;

export const Contacts = styled.div`
  @media (min-width: 680px) {
    padding: 40px 0 0 0;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }
`;

export const Info = styled.div`
  @media (min-width: 1024px) {
    width: 350px;
  }

  @media (max-width: 679px) {
    margin-top: 20px;
  }
`;

export const Logo = styled(LogoIcon)`
  display: block;

  width: 200px;

  fill: var(${COLOR.NEUTRAL_900});
`;

export const Text = styled.div`
  @media (min-width: 680px) {
    margin-right: 40px;
  }
`;

export const Title = styled.h2`
  ${TEXT.TITLE_2}
  color: var(${COLOR.NEUTRAL_900});
`;

export const Description = styled.div`
  ${TEXT.BODY_DEFAULT}
  color: var(${COLOR.NEUTRAL_500});

  margin-top: 10px;
`;

export const Social = styled.div`
  margin-top: 10px;

  display: flex;
  align-items: center;
`;

export const SocialCircle = styled.a`
  width: 40px;
  height: 40px;
  border-radius: 50%;

  display: flex;

  transition: var(${TRANSITION.DEFAULT});
  transition-property: background-color;

  svg {
    margin: auto;
    display: block;
    width: 24px;
    height: 24px;

    transition: var(${TRANSITION.DEFAULT});
    transition-property: fill;
  }

  &:not(:hover) svg {
    fill: var(${COLOR.NEUTRAL_500});
  }

  &:hover {
    background-color: var(${COLOR.NEUTRAL_10});

    svg {
      fill: var(${COLOR.NEUTRAL_900});
    }
  }

  & + & {
    margin-left: 16px;
  }
`;

export const Divider = styled.div`
  opacity: 0.5;

  @media (min-width: 680px) {
    margin: 65px 0;
  }

  @media (max-width: 679px) {
    margin: 20px 0;
  }

  height: 1px;
  background: var(${COLOR.NEUTRAL_800});
`;

export const Bottom = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

export const Links = styled.div`
  display: flex;
  align-items: center;

  @media (min-width: 680px) {
    margin-left: -30px;
  }

  @media (max-width: 679px) {
    width: 100%;
    justify-content: space-between;
  }
`;

export const Link = styled.a`
  @media (min-width: 680px) {
    padding: 0 30px;

    & + & {
      margin-left: 20px;
    }
  }

  ${TEXT.NAVIGATION}

  transition: var(${TRANSITION.DEFAULT});
  transition-property: color;

  color: var(${COLOR.NEUTRAL_500});

  &:hover {
    color: var(${COLOR.NEUTRAL_800});
  }

  &:active {
    color: var(${COLOR.NEUTRAL_900});
    transition: none;
  }
`;

export const Copyright = styled.div`
  ${TEXT.NAVIGATION}

  color: var(${COLOR.NEUTRAL_500});

  @media (max-width: 679px) {
    width: 100%;
    margin-top: 20px;
  }
`;
