import { styled } from '@linaria/react';

import { COLOR, TEXT, SHADOW, TRANSITION, ANIMATION } from 'theme/vars';

import { Tag as _Tag } from 'components/Controls';

export const Wrapper = styled.div`
  transition: var(${TRANSITION.DEFAULT});
  transition-property: transform;

  box-shadow: var(${SHADOW.DEFAULT});
  background-color: var(${COLOR.WHITE});

  ${ANIMATION.FADE_IN}
`;

export const Preview = styled.div`
  @media (min-width: 420px) {
    padding: 40px 40px 10px;
  }

  @media (max-width: 419px) {
    padding: 20px 20px 10px;
  }

  position: relative;
  overflow: hidden;
`;

export const Image = styled.div`
  display: table;
  margin: 0 auto;
`;

export const Info = styled.div`
  @media (min-width: 420px) {
    padding: 0px 40px 30px;
  }

  @media (max-width: 419px) {
    padding: 0px 20px 20px;
  }
`;

export const Title = styled.h3`
  ${TEXT.TITLE_5}

  color: var(${COLOR.NEUTRAL_900});
`;

export const Description = styled.p`
  white-space: pre-wrap;

  ${TEXT.BODY_DEFAULT}

  color: var(${COLOR.NEUTRAL_500});
`;

export const Tags = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  margin-top: 5px;
`;

export const Tag = styled(_Tag)`
  margin-right: 10px;
  margin-bottom: 10px;
`;
