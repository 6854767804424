import { styled } from '@linaria/react';

import { TEXT, COLOR, SIZE } from 'theme/vars';

import { Work as _Work } from './components';

export const Wrapper = styled.section`
  padding: 60px 0;
  background-color: var(${COLOR.NEUTRAL_100});
`;

export const Content = styled.div`
  @media (min-width: 1280px) {
    padding: 0 110px;
    max-width: var(${SIZE.CONTENT_WIDTH});
    margin: auto;
  }

  @media (min-width: 420px) and (max-width: 1279px) {
    padding: 0 40px;
  }

  @media (max-width: 419px) {
    padding: 0 20px;
  }

  margin: 0 auto;
`;

export const Title = styled.h2`
  color: var(${COLOR.NEUTRAL_900});

  ${TEXT.TITLE_2}

  max-width: 700px;
`;

export const Items = styled.div`
  margin-top: 40px;
  display: grid;
  grid-row-gap: 40px;
  grid-column-gap: 40px;

  @media (min-width: 1280px) {
    grid-template-columns: 1fr 1fr 1fr;

    > div:nth-child(3n - 1) {
      transition-delay: 0.15s;
    }

    > div:nth-child(3n) {
      transition-delay: 0.3s;
    }
  }

  @media (min-width: 900px) and (max-width: 1279px) {
    grid-template-columns: 1fr 1fr;

    > div:nth-child(2n) {
      transition-delay: 0.15s;
    }
  }

  @media (max-width: 899px) {
    grid-template-columns: 1fr;
  }
`;

export const Work = styled(_Work)``;
