import { styled } from '@linaria/react';

import { Button } from 'components/Controls';
import { COLOR, SIZE, TEXT } from 'theme/vars';

export const Wrapper = styled.main`
  @media (min-width: 768px) {
    padding: 0 40px 40px;
  }

  @media (max-width: 767px) {
    padding: 0 20px 20px;
  }

  display: flex;
  flex-direction: column;

  @media (min-width: 1024px) {
    min-height: calc(100vh - var(${SIZE.HEADER_HEIGHT}));
  }
`;

export const Content = styled.div`
  position: relative;
  margin: 0 auto;
  max-width: var(--content-width);
  width: 100%;

  @media (min-width: 1280px) {
    padding: 80px 110px;
  }

  @media (min-width: 1024px) and (max-width: 1279px) {
    padding: 40px 40px;
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    padding: 40px;
    overflow: hidden;
  }

  @media (max-width: 767px) {
    flex-direction: column;
    align-items: flex-start;
    padding-bottom: 400px !important;
    overflow: hidden;
  }

  @media (min-width: 450px) and (max-width: 767px) {
    padding: 20px 40px;
  }

  @media (max-width: 449px) {
    padding: 20px 20px;
  }

  display: flex;
  align-items: center;

  flex: 1;

  background-color: var(${COLOR.NEUTRAL_100});
`;

export const Left = styled.div`
  position: relative;
  z-index: 10;
`;

export const Title = styled.h1`
  @media (min-width: 1280px) {
    ${TEXT.HERO_LARGE}
    width: 620px;
  }

  @media (min-width: 1024px) and (max-width: 1279px) {
    ${TEXT.HERO_MEDIUM}
    width: 430px;
  }

  @media (max-width: 1023px) {
    ${TEXT.HERO_SMALL}
    max-width: 270px;
  }

  color: var(${COLOR.NEUTRAL_900});

  mark {
    background: transparent;
    color: var(${COLOR.ORANGE_NORMAL});
  }
`;

export const Icon = styled.span`
  display: inline-flex;
  border-radius: 50%;
  vertical-align: middle;

  background-color: var(${COLOR.ORANGE_NORMAL});

  svg {
    margin: auto;

    display: block;

    fill: var(${COLOR.WHITE});
  }

  @media (min-width: 1024px) {
    width: 48px;
    height: 48px;

    margin-right: 16px;

    svg {
      width: 24px;
      height: 24px;
    }
  }

  @media (max-width: 1023px) {
    width: 24px;
    height: 24px;

    margin-right: 8px;

    svg {
      width: 16px;
      height: 16px;
    }
  }
`;

export const Description = styled.p`
  @media (min-width: 1024px) {
    max-width: 440px;
    margin-top: 32px;
  }

  @media (max-width: 1023px) {
    max-width: 350px;
    margin-top: 16px;
  }

  color: var(${COLOR.NEUTRAL_900});

  ${TEXT.BODY_LARGE}
`;

export const ContactButton = styled(Button)`
  margin-top: 48px;
`;

export const Photo = styled.div`
  position: absolute;

  right: 0px;
  aspect-ratio: 570 / 712;

  @media (min-width: 1024px) {
    bottom: 0px;
    height: calc(100% + 30px);
    max-height: 1000px;
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    top: 0;
    height: calc(130% + 0px);
  }

  @media (max-width: 767px) {
    left: 50%;
    right: 0;
    transform: translate(-50%, 0);
    position: absolute;
    height: 600px;
    bottom: -240px;
  }
`;
