import * as React from 'react';

import { LINK_ANCHORS } from 'utils';

import { PencilAndRuler } from 'theme/icons';
import { StaticImage } from 'gatsby-plugin-image';

import {
  Wrapper,
  Content,
  Left,
  Title,
  Icon,
  Description,
  Photo,
} from './styled';

function Main() {
  return (
    <Wrapper id={LINK_ANCHORS.HOME}>
      <Content>
        <Left>
          <Title>
            Building websites, apps, chatbots,
            <br />
            <Icon>
              <PencilAndRuler />
            </Icon>
            <mark>experience</mark>
          </Title>

          <Description>
            an <b>Engineer</b> and <b>Developer</b> of digital systems. I
            specialize in React.js, Node.js, modern UI/UX and frontend
            performance.
          </Description>
        </Left>

        <Photo>
          <StaticImage
            layout='fullWidth'
            width={570}
            src='./assets/photo.png'
            placeholder='none'
            quality={100}
            alt='Anton Ignatev'
          />
        </Photo>
      </Content>
    </Wrapper>
  );
}

export default Main;
