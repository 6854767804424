import * as React from 'react';

import { LINK_ANCHORS } from 'utils';

import { StaticImage } from 'gatsby-plugin-image';

import { Wrapper, Content, Title, Items, Work } from './styled';

function Works() {
  const works = [
    {
      title: 'SberCloud',
      tags: ['React', 'Micro Frontend'],
      description: 'Cloud and ML services provider',
      to: '/works/sbercloud',
      image: (
        <StaticImage
          alt='SberCloud'
          src='../../../../images/sbercloud.png'
          width={300}
        />
      ),
    },
    {
      title: 'ERG',
      tags: ['React', 'Micro Frontend'],
      to: '/works/erg',
      description: 'HR management system',
      image: (
        <StaticImage alt='ERG' src='../../../../images/erg.png' width={300} />
      ),
    },
    {
      title: 'RSV',
      tags: ['React'],
      to: '/works/rsv',
      description: 'National competion platform',
      image: (
        <StaticImage alt='RSV' src='../../../../images/rsv.png' width={300} />
      ),
    },
    {
      title: 'Valo Cloud',
      tags: ['React'],
      to: '/works/valo-cloud',
      description: 'Self-hosted cloud storage',
      image: (
        <StaticImage alt='ERG' src='../../../../images/valo.png' width={300} />
      ),
    },
    {
      title: 'Pharm Portal',
      tags: ['React', 'Node.js', 'SSR'],
      to: '/works/pharm-portal',
      description: 'Pharmaceutical catalog',
      image: (
        <StaticImage
          alt='Pharm Portal'
          src='../../../../images/pharm.png'
          width={300}
        />
      ),
    },
    {
      title: 'Chatforma',
      tags: ['React', 'Node.js'],
      to: '/works/chatforma',
      description: 'Chatbot constructor',
      image: (
        <StaticImage
          alt='Chatforma'
          src='../../../../images/chatforma.png'
          width={300}
        />
      ),
    },
    {
      title: 'RuCenter',
      tags: ['React'],
      to: '/works/rucenter',
      description: 'Hosting control panel',
      image: (
        <StaticImage
          alt='RuCenter'
          src='../../../../images/nic.png'
          width={300}
        />
      ),
    },
    {
      title: 'AIS VA',
      tags: ['React'],
      to: '/works/aisva',
      description: 'Internal audit tool',
      image: (
        <StaticImage
          alt='AIS VA'
          src='../../../../images/aisva.png'
          width={300}
        />
      ),
    },
    {
      title: 'Tutu',
      tags: ['React', 'SSR'],
      description: 'Bus tickets aggregator',
      to: '/works/tutu',
      image: (
        <StaticImage
          alt='RuCenter'
          src='../../../../images/buses.png'
          width={300}
        />
      ),
    },
  ];

  return (
    <Wrapper id={LINK_ANCHORS.WORKS}>
      <Content>
        <Title>
          The projects I&apos;ve launched and companies I&apos;ve worked with
        </Title>

        <Items>
          {works.map(({ title, description, tags, image }, index) => (
            <Work
              key={index}
              title={title}
              description={description}
              tags={tags}
              image={image}
            />
          ))}
        </Items>
      </Content>
    </Wrapper>
  );
}

export default Works;
