import * as React from 'react';

import { Wrapper, Icon, Text, Title, Description } from './styled';

type ServiceProps = {
  className?: string;

  icon: React.ReactNode;
  title: string;
  description: string;

  circleColor: string;

  visible?: boolean;
};

function Service({
  className,
  title,
  icon,
  circleColor,
  description,
  visible,
}: ServiceProps) {
  return (
    <Wrapper className={className} data-visible={visible || undefined}>
      <Icon style={{ backgroundColor: circleColor }}>{icon}</Icon>

      <Text>
        <Title>{title}</Title>

        <Description>{description}</Description>
      </Text>
    </Wrapper>
  );
}

export default Service;
