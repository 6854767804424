import * as React from 'react';

import { LINK_ANCHORS } from 'utils';

import { useInViewport } from 'react-in-viewport';

import {
  Wrapper,
  Content,
  Text,
  Header,
  SubHeader,
  Description,
  ContactButton,
  Services,
  Service,
  Column,
} from './styled';

import Diagram from './icons/diagram.svg';
import Gear from './icons/gear.svg';
import Web from './icons/web.svg';

function About() {
  const [visible, setVisible] = React.useState(false);

  const ref = React.useRef(null);

  const { inViewport } = useInViewport(ref, { threshold: 0.5 });

  React.useEffect(() => {
    if (inViewport) {
      setVisible(true);
    }
  }, [inViewport]);

  return (
    <Wrapper id={LINK_ANCHORS.ABOUT}>
      <Content>
        <Text>
          <Header>My expertise</Header>

          <SubHeader>What I do for{'\n'}my customers</SubHeader>

          <Description>
            For more than 8 years I develop websites, mobile apps, chatbots and
            other digital tools to help companies create and launch their
            products.
          </Description>

          <ContactButton
            onClick={e => {
              e.preventDefault();

              const element = document.getElementById(LINK_ANCHORS.CONTACT);

              if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
              }
            }}
          >
            Contact me
          </ContactButton>
        </Text>

        <Services>
          <Column>
            <Service
              circleColor='rgba(134, 199, 220, 0.08)'
              title='Consulting & Audit'
              description='Finding ways to improve development, quality and performance.'
              icon={<Diagram />}
              visible={visible}
            />
          </Column>

          <Column ref={ref}>
            <Service
              circleColor='rgba(94, 58, 238, 0.08)'
              title='Web development'
              description='Building complex websites or web applications with React and Node.js.'
              icon={<Web />}
              visible={visible}
            />

            <Service
              circleColor='rgba(181, 155, 121, 0.08)'
              title='Architecture design'
              description='Designing a modern, scalable and flexible architecture for your project.'
              icon={<Gear />}
              visible={visible}
            />
          </Column>
        </Services>
      </Content>
    </Wrapper>
  );
}

export default About;
