import { styled } from '@linaria/react';
import { TEXT, COLOR, ANIMATION } from 'theme/vars';

export const Wrapper = styled.div`
  @media (min-width: 380px) {
    width: 290px;
    height: 270px;
    padding: 32px;
  }

  @media (max-width: 379px) {
    width: 100%;
    padding: 20px;
  }

  display: flex;
  flex-direction: column;

  background-color: var(${COLOR.WHITE});

  ${ANIMATION.FADE_IN}
`;

export const Icon = styled.div`
  width: 28px;
  height: 28px;
  border-radius: 50%;
  flex: 0 0 auto;

  position: relative;

  svg {
    display: block;
    width: 24px;
    height: 24px;
    position: absolute;
    top: -6px;
    right: -6px;
  }
`;

export const Text = styled.div`
  margin-top: auto;
`;

export const Title = styled.h4`
  ${TEXT.TITLE_5}
  white-space: pre-wrap;

  color: var(${COLOR.NEUTRAL_900});

  @media (max-width: 379px) {
    margin-top: 20px;
  }
`;

export const Description = styled.p`
  white-space: pre-wrap;

  margin-top: 16px;

  ${TEXT.BODY_DEFAULT}

  color: var(${COLOR.NEUTRAL_500});
`;
