import * as React from 'react';

import { LINKS, LINK_ANCHORS, SOCIAL } from 'utils';

import {
  Wrapper,
  Content,
  Contacts,
  Info,
  Logo,
  Text,
  Description,
  Social,
  Title,
  Divider,
  SocialCircle,
  Bottom,
  Links,
  Link,
  Copyright,
} from './styled';

export function Footer() {
  return (
    <Wrapper id={LINK_ANCHORS.CONTACT}>
      <Content>
        <Contacts>
          <Text>
            <Title>Let's Talk!</Title>

            <Description>
              I am always open to discuss your project,
              <br />
              improve your product or help with
              <br />
              challenges in your development.
            </Description>
          </Text>

          <Info>
            <Logo />

            <Social>
              {SOCIAL.map(({ href, icon: Icon }, index) => (
                <SocialCircle
                  href={href}
                  target={href.includes('mailto') ? undefined : '_blank'}
                  key={index}
                >
                  <Icon />
                </SocialCircle>
              ))}
            </Social>
          </Info>
        </Contacts>

        <Divider />

        <Bottom>
          <Links>
            {LINKS.map(({ title, href }, index) => (
              <Link
                href={href}
                key={index}
                onClick={e => {
                  const element = document.querySelector(href.substring(1));

                  const path = window.location.pathname;

                  if (element && path === '/') {
                    e.preventDefault();

                    if (href === `/#${LINK_ANCHORS.HOME}`) {
                      window.scrollTo({ top: 0, behavior: 'smooth' });
                    } else {
                      element.scrollIntoView({
                        behavior: 'smooth',
                      });
                    }
                  }
                }}
              >
                {title}
              </Link>
            ))}
          </Links>

          <Copyright>© All rights reserved</Copyright>
        </Bottom>
      </Content>
    </Wrapper>
  );
}

export default Footer;
